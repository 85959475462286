<template>
  <v-container>
    <form action="" @submit.prevent="submitFilter">
      <v-row no-gutters align="center">
        <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
          <span class="p-0 m-0">{{ $t("message.label-id") }}</span>
          <v-text-field
            class="p-0 mt-1"
            outlined
            v-model="categoryId"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
          <span class="p-0 m-0">{{ $t("message.label-name") }}</span>
          <v-text-field
            class="p-0 mt-1"
            outlined
            v-model="name"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12" md="3" xs="4">
          <span class="p-0 m-0">{{
            $t("message.label-parent-category")
          }}</span>
          <v-autocomplete
          class="mt-1"
            v-model="parentCategoryId"
            :items="categoryList"
            item-text="category_name"
            item-value="id"
            outlined
            clearable
            persistent-hint
            :menu-props="{ maxWidth: '20%' }"
          >
          <template v-slot:item="{ item }">
            <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
          </template>
          </v-autocomplete>
        </v-col>
        <v-col class="pr-2 pb-2" style="width: 180px">
          <span class="p-0 m-0">{{ $t("message.label-status") }}</span>
          <v-select
          class="mt-1"
            v-model="statusId"
            :items="statusList"
            item-text="name"
            item-value="id"
            outlined
            clearable
          >
          </v-select>
        </v-col>
        <v-col class="mb-5" cols="12" md="2" xs="4">
          <v-btn
            rounded
            type="submit"
            color="primary"
            style="float: right;"
          >
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.btn-search") }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterCategory",
  data: () => ({
    iconSearch: mdiMagnify,
    statusList: [
      { id: 1, name: "アクティブ" },
      { id: 0, name: "インアクティブ" },
    ],
    statusId: "",
    categoryId: "",
    parentCategoryId: "",
    name: "",
  }),
  computed: {
    // parentCategory() {
    //   return this.$store.state.category_module.categoryParent;
    // },

    categoryList() {
      return this.$store.state.category_module.categoryAll;
    },
  },
  methods: {
    // fetchParentCategory() {
    //   this.$store.dispatch("category_module/fetchParentCategory");
    // },

    fetchCategoryList() {
      this.$store.dispatch("category_module/fetchAllCategory");
    },
    setParentCategoryFilter() {
      this.$store.dispatch("category_module/setCategoryFilter", {
        categoryId: this.categoryId,
      });
    },
    setStatusFilter() {
      this.$store.dispatch("category_module/setCategoryFilter", {
        statusId: this.statusId,
      });
    },
    submitFilter() {
        if (this.statusId == null) this.statusId = "" 
        if (this.categoryId === null) this.categoryId = "" 
        if (this.name === null) this.name = ""
        if (this.parentCategoryId === null) this.parentCategoryId = ""
      this.$store.dispatch("category_module/fetchCategoryList", {
        statusId: this.statusId,
        categoryId: this.categoryId,
        name: this.name,
        parentCategoryId: this.parentCategoryId,
        page: 1
      });
    },
    initFilter() {
      this.statusId = this.$store.state.category_module.categoryFilter.statusId;
      this.categoryId = this.$store.state.category_module.categoryFilter.categoryId;
      this.name = this.$store.state.category_module.categoryFilter.name;
      this.parentCategoryId = this.$store.state.category_module.categoryFilter.parentCategoryId;
    },
  },
  created() {
    this.initFilter()
    // this.fetchParentCategory();
    this.fetchCategoryList();
  },
};
</script>

<style>
.v-input .v-input__control .v-text-field__details {
  /* display: none; */
}
.col-filter-input {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
.text-field-class .v-input__control input {
  font-size: 12px;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner{
    margin-top: 5px !important;
    align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
    margin-top: 0px !important;
    align-self: center !important;

}
</style>
