<template>
  <v-container>
    <v-toolbar flat>
      <h3>{{ $t("message.title-category") }}</h3>
      <v-divider class="mex-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- routing create category -->
      <template>
        <router-link to="/addCategory" style="text-decoration: none;">
          <v-row>
            <v-btn rounded color="primary">
              <v-icon>
                mdi-plus
              </v-icon>
              {{ $t("message.title-add-category") }}
            </v-btn>
          </v-row>
        </router-link>
      </template>
    </v-toolbar>

    <FilterCategory />

    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
    ></v-pagination>

    <!-- data table -->
    <v-data-table
      :headers="headers"
      :items="categoryList"
      :hide-default-footer="true"
      class="elevation-0"
      disable-sort
      justify="center"
      :server-items-length="orderHeader.total"
      :options.sync="option"
      :page="orderHeader.currentPage"
    >
      <!-- action button itemlist loop -->
      <template v-slot:[`item.is_active`]="{ item }">
        <div v-if="item.is_active">
          Active
        </div>
        <div v-else>
          Non Active
        </div>
      </template>

      <!-- action button itemlist loop -->
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          :to="'/editCategory/' + item.id"
          style="text-decoration: none;"
        >
          <v-icon
            color="indigo"
            small
            class="mr-2"
            @click.prevent="toUpdate(item)"
          >
            mdi-pencil
          </v-icon>
        </router-link>
        <v-icon color="red" small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>

      <!-- dialog delete confirmation -->
      <template v-slot:top="">
        <!-- top pagination extend -->
       

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{
              $t("message.confirm-delete")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                {{ $t("message.btn-cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                {{ $t("message.btn-ok") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterCategory from "../components/FilterCategory.vue";
export default {
  components: { FilterCategory },
  name: "CategoryMaster",
  data: () => ({
    dialogDelete: false,
    deletededId: "",
    option: {},
    message: {},
    page: 1,
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    categoryList: {
      handler() {
        this.page = parseInt(this.$store.state.category_module.page);
      },
      deep: true,
    },
  },

  created() {
    this.initializeCategory();
  },

  computed: {
    isLength() {
      return this.$store.state.category_module.is_length
    },
    lengthPage() {
      return this.$store.state.category_module.length_page;
    },
    categoryList() {
      return this.$store.state.category_module.categoryList;
    },
    orderHeader() {
      return this.$store.state.category_module.categoryHeader;
    },
    headers() {
      return [
        {
          text: this.$t("message.label-id"),
          align: "start",
          value: "id",
        },
        { text: this.$t("message.label-name"), value: "category_name" },
        { text: this.$t("message.label-parent-category"), value: "parent" },
        { text: this.$t("message.label-slug"), value: "slug" },
        { text: this.$t("message.label-status"), value: "is_active" },
        { text: this.$t("message.label-action"), value: "actions" },
      ];
    },
  },

  methods: {
    changePage(event) {
      return this.$store.dispatch("category_module/fetchCategoryList", {
        page: event,
      });
    },
    initializeCategory() {
      // const { page, itemsPerPage } = this.option;
      let pageInit = parseInt(this.$store.state.category_module.page);
      this.page = parseInt(this.$store.state.category_module.page);
      this.$store.dispatch("category_module/fetchCategoryList", {
        page: pageInit,
        // perpage: itemsPerPage,
      });
    },

    toUpdate(item) {
      this.$router.push(`/editCategory/${item.id}`);
    },

    deleteItem(id) {
      this.deletededId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let id = this.deletededId;
      // uncomment text below to delete item
      this.message.title = this.$t(
        "message.title-delete-category-success-message"
      );
      this.$store.dispatch("category_module/deleteCategory", {
        id,
        message: {
          title: this.message.title,
        },
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },

  watch: {
    option: {
      handler() {
        this.initializeCategory();
      },
      deep: true,
    },
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
